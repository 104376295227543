// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Dr. Sameer Nanivadekar Portfolio",
  description:
    "To further, the cause of academia by implementing innovative educational practices through my knowledge and industrial experience along with my creative thinking, contributing towards the holistic development of students and myself in this exciting STEAM education era.",
  og: {
    title: "Dr. Sameer Nanivadekar Portfolio",
    type: "website",
    url: "https://drsameer.me",
  },
};

//Home Page
const greeting = {
  title: "Dr. Sameer Nanivadekar",
  logo_name: "Dr. Sameer Nanivadekar",
  subTitle:
    "To further, the cause of academia by implementing innovative educational practices through my knowledge and industrial experience along with my creative thinking, contributing towards the holistic development of students and myself in this exciting STEAM education era.",
  resumeLink:
    "https://docs.google.com/document/d/1sluBE9dhdQVvVjtS7HTisiKz3DUjCkqW/edit?usp=sharing&ouid=117124836921099383394&rtpof=true&sd=true",
  portfolio_repository: "",
  githubProfile: "",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/dr-sameer-nanivadekar-525717167/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:contact@drsameer.me",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  {
    name: "Facebook",
    link: "https://www.facebook.com/sameer.nanivadekar",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Career Coach",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Cultivating and managing excellent relationships with clients from diverse backgrounds, professional levels, and industries, identifying their professional and personal goals; evaluating their skill sets; developing and implementing customized strategic action plans; and effectively providing career guidance and placement support to help them achieve their objectives and ensure that students embark on a career path that meets their individual interests, skills, and abilities and allows them to be passionate about the career they choose.",
        "⚡ Organizing and leading professional and career development workshops on a variety of topics such as time management, procrastination, soft and interpersonal skills, resume writing, mock interviews, job search techniques, social media networking, and job readiness.",
        "⚡ Have a solid understanding of current job market trends, various career requirements, and job search/readiness strategies and maintain a trusting relationship with businessmen and renowned industrialists to promote career opportunities among clients.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Career Counselor",
      fileName: "FullStackImg",
      skills: [
        "⚡ A dynamic and results-oriented individual with demonstrated years of experience in career counselling and providing people with direction and advice, possesses creativity and strong analytical skills essential in deciphering variables for the development of academic and career objectives, effectively motivates, understands, and relates with people from all socioeconomic backgrounds, race, and culture, has excellent communication and writing skills, and possesses high physical and mental fitness to handle diverse issues and concerns.",
        "⚡ Capable of forming positive working relationships with faculty, administration, and co-workers to achieve desired results; taking on a leadership role for career counselling projects; assisting in the development and delivery of career/life education and outreach and service programs.",
        "⚡ Provide a multi-faceted approach to life, education, and employment obstacles, through targeted counselling. Suggested alternate career paths in accordance with clients unmet needs.",
        "⚡ Extensive knowledge of human psychology, which helps in dealing with students and deciding the correct career option that would suit their interests and talent. Skilled in providing professional referral services for any student requiring extended treatment for social, personal, or psychiatric problems.",
        "⚡ Advice on career pathways and connections with education and businesses in developing and improving career planning activities that foster student success.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    // {
    //   title: "Cloud Infra-Architecture",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Experience working on multiple cloud platforms",
    //     "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
    //     "⚡ Deploying deep learning models on cloud to use on mobile devices",
    //     "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "GCP",
    //       fontAwesomeClassname: "simple-icons:googlecloud",
    //       style: {
    //         color: "#4285F4",
    //       },
    //     },
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Azure",
    //       fontAwesomeClassname: "simple-icons:microsoftazure",
    //       style: {
    //         color: "#0089D6",
    //       },
    //     },
    //     {
    //       skillName: "Firebase",
    //       fontAwesomeClassname: "simple-icons:firebase",
    //       style: {
    //         color: "#FFCA28",
    //       },
    //     },
    //     {
    //       skillName: "PostgreSQL",
    //       fontAwesomeClassname: "simple-icons:postgresql",
    //       style: {
    //         color: "#336791",
    //       },
    //     },
    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     {
    //       skillName: "Kubernetes",
    //       fontAwesomeClassname: "simple-icons:kubernetes",
    //       style: {
    //         color: "#326CE5",
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    //   {
    //     siteName: "HackerRank",    // title: "Address",
    // subtitle:
    //   "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    // avatar_image_path: "address_image.svg",
    // location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
    //     iconifyClassname: "simple-icons:hackerrank",
    //     style: {
    //       color: "#2EC866",
    //     },
    //     profileLink: "https://www.hackerrank.com/layman_brother",
    //   },
    //   {
    //     siteName: "Codechef",
    //     iconifyClassname: "simple-icons:codechef",
    //     style: {
    //       color: "#5B4638",
    //     },
    //     profileLink: "https://www.codechef.com/users/ashutosh_1919",
    //   },
    //   {
    //     siteName: "Codeforces",
    //     iconifyClassname: "simple-icons:codeforces",
    //     style: {
    //       color: "#1F8ACB",
    //     },
    //     profileLink: "http://codeforces.com/profile/layman_brother",
    //   },
    //   {
    //     siteName: "Hackerearth",
    //     iconifyClassname: "simple-icons:hackerearth",
    //     style: {
    //       color: "#323754",
    //     },
    //     profileLink: "https://www.hackerearth.com/@ashutosh391",
    //   },
    //   {
    //     siteName: "Kaggle",
    //     iconifyClassname: "simple-icons:kaggle",
    //     style: {
    //       color: "#20BEFF",
    //     },
    //     profileLink: "https://www.kaggle.com/laymanbrother",
    //   },
  ],
};

const degrees = {
  degrees: [
    {
      title: "LLB",
      subtitle: "Legum Baccalaureus(Bachelor of Laws)",
      logo_path: "iiitk_logo.jpg",
      alt_name: "",
      duration: "",
      descriptions: [],
    },
    {
      title: "MBA",
      subtitle: "Human Resource Management",
      logo_path: "hrm.jpeg",
      alt_name: "",
      duration: "",
      descriptions: [],
    },
    {
      title: "PhD",
      subtitle: "Electronics and Communication Engineering",
      logo_path: "extc.jpeg",
      alt_name: "",
      duration: "",
      descriptions: [],
    },
    {
      title: "B.E & M.E.",
      subtitle: "Electronics",
      logo_path: "extc1.jpeg",
      alt_name: "",
      duration: "",
      descriptions: [],
    },
    {
      title: "DEE",
      subtitle: "Electrical Engineering",
      logo_path: "electrical.jpeg",
      alt_name: "",
      duration: "",
      descriptions: [],
    },
    {
      title: "MA*",
      subtitle: "Psychology",
      logo_path: "iiitk_logo.jpg",
      alt_name: "",
      duration: "",
      descriptions: [],
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Leading People and Teams",
      subtitle: "- University of Michigan",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/fa3170b2ca2747a9691e303e26e29a72",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Dynamic Public Speaking",
      subtitle: "- University of Washington",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/086fdb898e6399ae0a5f5021fd4ef623",
      alt_name: "coursera.ai",
      color_code: "#2A73CC",
    },
    {
      title: "Business Technology Management",
      subtitle: "- University of Washington",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/65d813e0069dc0a97cdedd70ba8306f1",
      alt_name: "Google",
      color_code: "#2A73CC",
    },
    {
      title: "Project Management & Other Tools for Career Development",
      subtitle: "- University of California, Irvine",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/e570f847e1e1a624788e6dfd0164b962",
      alt_name: "IBM",
      color_code: "#2A73CC",
    },
    {
      title: "Adapting: Career Development",
      subtitle: "- Macquarie University",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/f735bfd4c7d91ec15fa601938746eac6",
      alt_name: "Microsoft",
      color_code: "#2A73CC",
    },
    {
      title: "Good with Words: Writing and Editing",
      subtitle: "- University of Michigan",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/e19649f69af74b65436760ed61f89454",
      alt_name: "IBM",
      color_code: "#2A73CC",
    },
    {
      title: "Leadership Development for Engineers",
      subtitle: "- Rice University",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/f5a96dc54f46cbc867db30ab0611af84",
      alt_name: "Google",
      color_code: "#2A73CC",
    },
    {
      title: "Career Success",
      subtitle: "- University of California, Irvine",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/14917a61b6a232efdd3e7ce4032e5e52",
      alt_name: "deeplearning.ai",
      color_code: "#2A73CC",
    },
    {
      title: "STEM.org Certified™",
      subtitle: "- STEM.org",
      logo_path: "coursera_logo.png",
      certificate_link: "https://sgq.io/zOe7pr1",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - PRESENT",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "drsameer_round_pic.png",
    description:
      "To further, the cause of academia by implementing innovative educational practices through my knowledge and industrial experience along with my creative thinking, contributing towards the holistic development of students and myself in this exciting STEAM education era.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://drsameer.medium.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    // title: "Address",
    // subtitle:
    //   "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    // avatar_image_path: "address_image.svg",
    // location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 7720982345",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
